import React, { useEffect, useState } from 'react';
import { CircularProgress, MenuItem, Select } from '@mui/material';
import List from '@mui/material/List';
import { RunOutfeedPackLine } from './RunOutfeedPackLine';
import Alert from '@mui/material/Alert';
import styled from 'styled-components';
import { useRunOutfeedQuery } from '../../../generated/graphql';
import { Error } from '../../Utility/Error';
import { useWorkstationRuns } from '../../../graphql/queries';
import { useCurrentRunId } from '../../../store';

export interface RunOutfeedProps {
  runTypeId: string;
}

export const RunOutfeed: React.FC<RunOutfeedProps> = ({ runTypeId }) => {
  const [currentRunId] = useCurrentRunId();

  const [selectedRunId, setSelectedRunId] = useState<string | null>(
    currentRunId,
  );

  // When they go to a different run, update the state automatically, but the
  // user can still override afterward via the select
  useEffect(() => {
    setSelectedRunId(currentRunId);
  }, [currentRunId]);

  const runs = useWorkstationRuns(runTypeId);

  const run = useRunOutfeedQuery({
    variables: { runId: selectedRunId! },
    skip: selectedRunId == null,
    pollInterval: 30000
  });

  if (runs.error || run.error) {
    return <Error />;
  }

  if (run.loading) {
    return <CircularProgress />;
  }

  const packs = run.data?.run?.outfeed.packs ?? [];

  packs.sort((a, b) => {
    const aDate = new Date(a.processDate);
    const bDate = new Date(b.processDate);
    return bDate.getTime() - aDate.getTime();
  });

  const sortedRuns = [...runs.data?.runs ?? []];
  if (sortedRuns) {
    sortedRuns.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  return (
    <RecentPacksStyle>
      <div className="search">
        <Select
          style={{ minWidth: 250 }}
          value={selectedRunId ?? ''}
          onChange={(e) => setSelectedRunId(e.target.value as string)}
        >
          {sortedRuns.map((run) => (
            <MenuItem key={run.id} value={run.id}>
              {run.date
                ? <div style={{ 'width': '100%', 'display': 'flex', 'justifyContent': 'space-between' }}>
                  <span>{run.id}</span>
                  <span style={{ 'color': '#A6A6A6' }}> {run.date}</span>
                </div>
                : run.id}
            </MenuItem>
          ))}
        </Select>
      </div>
      {selectedRunId != null &&
        (packs.length > 0 ? (
          <List className="packs">
            {packs.map((pack) => (
              <RunOutfeedPackLine
                key={pack.packId}
                runId={selectedRunId}
                pack={pack}
              />
            ))}
          </List>
        ) : (
          !run.loading && (
            <Alert severity="info" className="no-results">
              No packs in the outfeed
            </Alert>
          )
        ))}
    </RecentPacksStyle>
  );
};

const RecentPacksStyle = styled.div`
  .search {
    display: inline-flex;
    margin-bottom: 10px;
  }
  .no-results {
    align-items: center;
    font-size: large;
  }
  .packs {
    height: 510px;
    overflow-y: auto;
  }
`;
