import gql from 'graphql-tag';
import {
  addDays,
  useNow,
} from '../components/Workstations/Planned/PlannedWorkstation';
import {
  FindStockItemByStockCodeQuery,
  TallyInput,
  useAutofillPackLogsQuery,
  useFindStockItemByStockCodeLazyQuery,
  useFindStockItemQuery,
  useWorkstationRunsQuery,
} from '../generated/graphql';

const STOCK_ITEM_FRAGMENT = gql`
  fragment StockItem on StockItem {
    stockCode
    name
    isActive
    description
    widthMillimeters
    thicknessMillimeters
    lengthMillimeters
    unitOfMeasure {
      name
    }
    kilograms
    metresCubed
    standardPackSize
    wrapped
    customerName
    isVariableLengthProduct
  }
`;

gql`
  query FindStockItemByStockCode($stockCode: ID!) {
    stockItem(stockCode: $stockCode) {
      ...StockItem
    }
  }
`;

gql`
  query FindStockItem($stockCode: ID!, $barcode: String!) {
    byCode: stockItem(stockCode: $stockCode) {
      ...StockItem
    }

    byBarcode: stockItems(barcodes: [$barcode], take: 1) {
      ...StockItem
    }
  }

  ${STOCK_ITEM_FRAGMENT}
`;

gql`
  query FindWorkstationByName($workstation: String!) {
    packWorkstationByName(name: $workstation) {
      id
      name
      type
      timberSmartRunTypeId
      stockAdjustmentsDisabled
      batchPrintingEnabled
      printer
      stickerPrinter
    }
  }
`;

gql`
  query RecentPackLogs($workstationId: ID, $take: Int!, $searchQuery: String) {
    packLogs(
      packWorkstationId: $workstationId
      take: $take
      searchQuery: $searchQuery
    ) {
      id
      packNumber
      quantity
      stockItem {
        stockCode
        unitOfMeasure {
          name
        }
      }
      lastUpdated
      deleted
    }
  }
`;

gql`
  query Printers {
    printers {
      id
    }
  }
`;

gql`
  query LabelTemplates {
    labelTemplates {
      id
      name
      category
      presetKind
    }
  }
`;

gql`
  query LabelTemplate($id: ID!) {
    labelTemplate(id: $id) {
      id
      name
      category
      defaultPrinter
      presetKind
      presets
      fields {
        name
        internalName
        preset
        extraInfo
        kind
        defaultLineCount
      }
    }
  }
`;

gql`
  query LabelTemplatePresetDownload($id: ID!) {
    labelTemplate(id: $id) {
      id
      presetDocument
    }
  }
`;

gql`
  query Run($id: ID!) {
    run(id: $id) {
      id
      complete
      date
      description
      outfeedPlan {
        lines {
          id
          linealPlanned
          piecesPlanned
          linealCompleted
          packBuild
          nextAction
          unitNumber
          wipProduct {
            id
            description
            setLength
          }
          finishedProduct {
            stockCode
            description
            thicknessMillimeters
            widthMillimeters
            lengthMillimeters
            standardPackSize
          }
        }
      }
    }
  }
`;

gql`
  query Runs($untilDate: Date, $workstationId: ID) {
    runs(untilDate: $untilDate, workstationId: $workstationId) {
      id
      complete
      date
      description
      workstation {
        name
        type
      }
      outfeedPlan {
        lines {
          id
        }
      }
    }
  }
`;

gql`
  query AutofillPackLogs($workstationId: ID!) {
    packLogs(packWorkstationId: $workstationId, take: 50) {
      id
      quantity
      deleted
      unitNumber
      tally {
        length
        pieces
      }
      stockItem {
        stockCode
        customerName
        unitOfMeasure {
          name
        }
      }
    }
  }
`;

gql`
  query WorkstationRuns($runTypeId: ID!, $untilDate: Date!) {
    runs(workstationId: $runTypeId, untilDate: $untilDate) {
      id
      date
    }
  }

  query RunOutfeed($runId: ID!) {
    run(id: $runId) {
      id
      outfeed {
        packs {
          packId
          productCode
          processDate
        }
      }
    }
  }
`;

export function useWorkstationRuns(runTypeId: string) {
  const now = useNow(300_000);
  return useWorkstationRunsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      runTypeId,
      untilDate: addDays(now, 7).toISOString(),
    },
  });
}

export function useFindStockByGTINQuery(code: string | null) {
  return useFindStockItemQuery({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { stockCode: code!, barcode: code! },
    skip: code == null,
  });
}

export function useLazyFindStockForPlanQuery(
  onStockItem: (data: FindStockItemByStockCodeQuery['stockItem']) => void,
) {
  return useFindStockItemByStockCodeLazyQuery({
    onCompleted: (data) => onStockItem(data.stockItem),
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });
}

export interface AutoFillEntry {
  id: string;
  stockCode: string;
  unitText: string;
  unitOfMeasure: string;
  quantity: number;
  tally: TallyInput[];
}

export function useAutofillQuery(workstationId: string) {
  // We do a manual join to keep the backend simple. The MLC API already
  // supports all of this, so hopefully we can make use of that soon enough
  const log = useAutofillPackLogsQuery({
    variables: { workstationId },
  });

  const logs = log.data?.packLogs
    ?.filter((x) => x && !x.deleted)
    .flatMap((x) => (x ? [x] : []));
  let relevantLogs: Array<AutoFillEntry> | null = null;
  if (logs) {
    // newest are first, so prioritise these
    const results = new Map<string, AutoFillEntry>();
    const key = (log: typeof logs[0]) =>
      `${log.quantity}#${log.stockItem.stockCode}`;
    for (const log of logs) {
      if (results.size === 3) break;
      if (results.has(key(log))) continue;
      results.set(key(log), {
        id: log.id,
        quantity: log.quantity,
        unitText:
          log.unitNumber ?? log.stockItem.customerName !== ''
            ? `Stock: ${log.stockItem.customerName}`
            : 'Stock',
        stockCode: log.stockItem.stockCode,
        unitOfMeasure: log.stockItem.unitOfMeasure.name,
        tally: log.tally.map((x) => ({
          length: x.length,
          pieces: x.pieces,
        })),
      });
    }

    relevantLogs = [...results.values()];
  }

  return {
    loading: log.loading,
    entries: relevantLogs,
    error: log.error,
  };
}

gql`
  query PackHistory(
    $packWorkstationId: ID
    $stockCode: ID
    $take: Int
    $startDate: DateTime
    $endDate: DateTime
    $searchQuery: String
  ) {
    packLogs(
      packWorkstationId: $packWorkstationId
      stockCode: $stockCode
      take: $take
      startDate: $startDate
      endDate: $endDate
      searchQuery: $searchQuery
    ) {
      id
      packNumber
      workstation {
        id
        name
      }
      lastUpdated
      stockItem {
        stockCode
        description
        unitOfMeasure {
          symbolCapitalized
        }
      }
      quantity
      tally {
        length
        pieces
      }
      unitNumber
      unitNumberCategory
      deleted
    }
  }

  query Workstations {
    packWorkstations {
      id
      name
    }
  }
`;
